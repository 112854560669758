import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../../backend/auth/auth.service';

@Injectable()
export class RoleGuard  {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return route.data.role === this.authService.role.roleNo || this.router.parseUrl(route.url.toString());
  }
  canMatch(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return route.data.role === this.authService.role.roleNo || this.router.parseUrl(segments.toString());
  }
}
